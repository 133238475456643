import { theme as defaultTheme } from "@chakra-ui/react"

const theme = {
  ...defaultTheme,
  styles: {
    ...defaultTheme.styles,
  },
  colors: {
    ...defaultTheme.colors,
    bullseyeOrange: {
      50: "#ffeedc",
      100: "#ffd2ae",
      200: "#ffb57e",
      300: "#ff994c",
      400: "#fe7c1a",
      500: "#EC6601",
      600: "#E84602",
      700: "#b34d00",
      800: "#803600",
      900: "#4e2000",
    },
    bullseyeGreen: {
      50: "#e4faef",
      100: "#c3ecd6",
      200: "#a0debd",
      300: "#7cd1a3",
      400: "#58c488",
      500: "#3fab6f",
      600: "#308557",
      700: "#215f3d",
      800: "#113924",
      900: "#001508",
    },
  },
}

export default theme
